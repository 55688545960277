<template>
  <div v-if="activity">

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <h4 id="top">Activity: <small>{{ activity.title }}</small></h4>
    </div>
    <div class="card">
      <div class="rainbow-background"></div>
      <table  class="card-table table">
        <tbody class="text-left">
        <tr>
          <th scope="col">ID</th>
          <td>{{ activity.id }}</td>
        </tr>
        <tr>
          <th scope="col">Title</th>
          <td>{{ activity.title }}</td>
        </tr>
        <tr>
          <th scope="col">Trainees Enabled?</th>
          <td>{{ activity.coach_impediment }}</td>
        </tr>
        <tr>
          <th scope="col">Audiences Enabled?</th>
          <td>{{ activity.topic_impediment }}</td>
        </tr>
        <tr>
          <th scope="col">Include In Reports?</th>
          <td>{{ activity.include_in_reports }}</td>
        </tr>
        <tr>
          <th scope="col">Report Column Number</th>
          <td>{{ getReportOrderCol() }}</td>
        </tr>
        <tr>
          <th scope="col">Created At</th>
          <td>{{ new Date(activity.created_at).toDateString() }}, {{ new Date(activity.created_at).toLocaleTimeString() }}</td>
        </tr>
        <tr>
          <th scope="col">Updated At</th>
          <td>{{ new Date(activity.updated_at).toDateString() }}, {{ new Date(activity.updated_at).toLocaleTimeString() }}</td>
        </tr>
        <tr>
          <th scope="col">Is Archived?</th>
          <td>{{ activity.is_archived }}</td>
        </tr>
        </tbody>
      </table>
      <div class="card-footer">
        <div class="btn-group float-right">
          <router-link :to="{ name: 'ActivityIndex' }" tag="button" class="btn btn-secondary">Back</router-link>
          <router-link :to="{ name: 'ActivityEdit' }" tag="button" class="btn btn-primary">Edit</router-link>
          <button v-on:click="$refs.activityDeleteModal.show()" type="button" class="btn btn-danger">Delete</button>
        </div>
      </div>
    </div>

    <activity-topics :activity_id="activity.id"/>

    <!-- Modal Component -->
    <b-modal ref="activityDeleteModal" header-bg-variant="danger" header-text-variant="light" title="Confirm Delete" ok-variant="danger" ok-title="Delete" @ok="deleteActivity" centered>
      <p>Deleting an activity will delete all Time Card Activities associated to it.</p>
    </b-modal>

  </div>
  <loading-overlay v-else/>
</template>

<script>
import { ActivityService } from '@/common/services/activity.service';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import ActivityTopics from '@/common/components/activity-topics.vue';

export default {
  name: 'ActivityShow',
  components: {
    LoadingOverlay,
    ActivityTopics,
  },
  data() {
    return {
      activity: null,
    };
  },
  created() {
    this.getActivity();
  },
  methods: {
    getActivity() {
      ActivityService.get(this.$route.params.id)
        .then((response) => {
          this.activity = response.data;
        });
    },
    deleteActivity() {
      ActivityService.destroy(this.$route.params.id)
        .then((response) => {
          this.$router.push({ name: 'ActivityIndex' });
          this.$notify({
            title: `Deleted ${response.data.title}`,
            text: 'Successfully deleted Activity.',
            type: 'success',
            width: 350,
          });
        });
    },
    getReportOrderCol() {
      if (!this.activity.report_order || this.activity.report_order === 0) {
        return 'N/A';
      }
      return this.activity.report_order;
    },
  },
};

</script>

<style lang="scss" scoped>
  @import "../../styles/views/activities";
</style>
