<template>
  <div v-if="activityTopics" class="card">
    <h1>Audience Cross Reference</h1>
    <table class="card-table table">
      <thead>
      <tr>
        <th>Sales Coach?</th>
        <th>Service Coach?</th>
        <th>Audience</th>
        <th>Activity</th>
      </tr>
      </thead>
      <tbody>
      <activity-topic-row v-for="activityTopic in activityTopics" :key="activityTopic.id" :activityTopic="activityTopic" />
      </tbody>
    </table>
  </div>
</template>

<script>
import { ActivityTopicService } from '@/common/services/activity_topic.service';
import ActivityTopicRow from '@/common/components/activity-topics-row.vue';

export default {
  name: 'ActivityTopics',
  props: {
    topic_id: Number,
    activity_id: Number,
  },
  components: {
    ActivityTopicRow,
  },
  data() {
    return {
      activityTopics: [],
    };
  },
  created() {
    this.getActivityTopics();
  },
  methods: {
    getActivityTopics() {
      const params = {
        activity_id: this.activity_id,
        topic_id: this.topic_id,
        is_archived: false,
        per_page: 1000,
      };
      ActivityTopicService.query(params)
        .then((response) => {
          this.activityTopics = response.data;
        });
    },
  },
};
</script>

<style scoped>

</style>
