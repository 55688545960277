<template>
    <tr v-if="activityTopic">
      <td>
        <input type="checkbox" v-model="activityTopic.sales_coach" @change="updateActivityTopic" />
      </td>
      <td>
        <input type="checkbox" v-model="activityTopic.service_coach" @change="updateActivityTopic" />
      </td>
      <td><router-link :to="{ name: 'TopicShow', params: { id: activityTopic.topic_id } }">{{ activityTopic.topic_title }}</router-link></td>
      <td><router-link :to="{ name: 'ActivityShow', params: { id: activityTopic.activity_id } }">{{ activityTopic.activity_title }}</router-link></td>
    </tr>
</template>

<script>
import { ActivityTopicService } from '@/common/services/activity_topic.service';

export default {
  name: 'ActivityTopicsRow',
  props: {
    activityTopic: Object,
  },
  methods: {
    updateActivityTopic() {
      ActivityTopicService
        .update(this.activityTopic.id, this.activityTopic);
    },
  },
};
</script>

<style scoped>

</style>
